<template>
  <div>
    <!-- <div class="nav">订购服务/版本升级</div> -->
    <div class="newProject">
      <div class="left">
        <div class="title">当前项目信息</div>
        <el-row>
          <el-col :span="8" class="nav_title">
            项目名称
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$store.state.current_project.organization.name}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="nav_title">
            当前版本信息
          </el-col>
          <el-col :span="6" class="nav_content">
            {{service_level}}
          </el-col>
          <el-col :span="10" class="nav_content">
            <el-button size="mini" type="primary" @click="extension('upgrade')" round v-if="this.$store.state.current_project.organization.service_level==2 || this.$store.state.current_project.organization.service_level==1">续费</el-button>
            <el-button size="mini" type="primary" @click="extension('upgrade')" round v-if="this.$store.state.current_project.organization.service_level==0">升级</el-button>
            <el-button size="mini" type="primary" @click="extension('extension')" round v-if="this.$store.state.current_project.organization.service_level==1 ">升级</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="nav_title">
            有效期
          </el-col>
          <el-col :span="16" class="nav_content">
            {{ (this.$store.state.current_project.organization.service_expire*1000) | dateformat("YYYY-MM-DD HH:mm")}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="nav_title">
            需要实体标签？
          </el-col>
          <el-col :span="16" class="nav_content">
            <el-button size="mini" type="primary" round @click="extension('/payHardWare')">购买标签</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="nav_title">
            需要帮助？
          </el-col>
          <el-col :span="16" class="nav_content">
            <i class="el-icon-phone-outline"></i> 020-28989907
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="nav_title">
            需要查看过往订单？
          </el-col>
          <el-col :span="16" class="nav_content">
            <router-link to="/orderlist">
              <el-button size="mini" type="primary" plain>订单列表</el-button>
            </router-link>
          </el-col>
        </el-row>
      </div>
      <div class="content">
        <div class="title">不同版本之间的区别</div>
        <el-table :data="tableData" size="mini" :height="605" border style="width:672px" :header-cell-style="objectSpanMethod" :span-method="arraySpanMethod">
          <el-table-column :show-overflow-tooltip='true' prop="" label="功能" width="150" align="center">
            <el-table-column prop="function" label="功能" width="150" align="center">
            </el-table-column>
            <el-table-column prop="content" label="功能" width="120" align="left">
            </el-table-column>
          </el-table-column>
          <el-table-column :show-overflow-tooltip='true' prop="free" label="免费版" width="100" align="center">
            <template slot-scope="scope">
              <div style="color:#67c23a" v-if="scope.row.free===true" class="el-icon-check check"></div>
              <div style="" v-else-if="scope.row.free">{{ scope.row.free }}</div>
              <div style="color:#eb3223;" class="el-icon-close close" v-else="scope.row.free"></div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip='true' prop="stand" label="基础版" width="100" align="center">
            <template slot-scope="scope">
              <div style="color:#67c23a" v-if="scope.row.stand===true" class="el-icon-check check"></div>
              <div style="" v-else-if="scope.row.stand">{{ scope.row.stand }}</div>
              <div style="color:#eb3223" class="el-icon-close close" v-else="scope.row.stand"></div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip='true' prop="more" label="高级版" width="100" align="center">
            <template slot-scope="scope">
              <div style="color:#67c23a" v-if="scope.row.more===true" class="el-icon-check check"></div>
              <div style="" v-else-if="scope.row.more">{{ scope.row.more }}</div>
              <div style="color:#eb3223" class="el-icon-close close" v-else="scope.row.more"></div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip='true' prop="self" label="定制版" width="100" align="center">
            <template slot-scope="scope">
              <div style="color:#67c23a" v-if="scope.row.self===true" class="el-icon-check check"></div>
              <div style="" v-else-if="scope.row.self">{{ scope.row.self }}</div>
              <div style="color:#eb3223" class="el-icon-close close" v-else="scope.row.self"></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { user, dataCation, files, account } from '@/utils/api';
import location from '@/utils/province_city_district.json';
import qs from 'qs';
export default {
  name:'indent',
  data() {
    return {
      tableData: [
        {
          free: true,
          stand: true,
          function: '人员管理',
          self: true,
          more: true,
          content: '新增、删除成员'
        },
        {
          free: false,
          stand: true,
          self: true,
          more: true,
          function: '人员管理',
          content: '多部门管理'
        },
        {
          free: '10人',
          stand: '不限',
          more: '不限',
          self: '不限',
          function: '人员管理',
          content: '成员数量'
        },
        {
          free: true,
          stand: true,
          self: true,
          more: true,
          function: '点位管理',
          content: '新建、删除点位'
        },
        {
          free: false,
          stand: true,
          self: true,
          more: true,
          function: '点位管理',
          content: '分组管理'
        },
        {
          free: false,
          stand: true,
          self: true,
          more: true,
          function: '点位管理',
          content: '自定义巡检内容'
        },
        {
          free: '10个',
          stand: '200个',
          more: '1000个',
          self: '不限',
          function: '点位管理',
          content: '点位数量'
        },
        {
          free: false,
          stand: true,
          more: true,
          self: true,
          function: '巡检',
          content: '成员实时巡检动态（地图显示）'
        },
        {
          free: true,
          stand: true,
          more: true,
          self: true,
          function: '巡检',
          content: '巡检方式：二维码'
        },
        {
          free: false,
          stand: true,
          more: true,
          self: true,
          function: '巡检',
          content: '巡检方式：NFC'
        },
        {
          free: false,
          stand: true,
          more: true,
          self: true,
          function: '巡检',
          content: '巡检方式：蓝牙信标'
        },
        {
          free: false,
          stand: false,
          function: '巡检',
          more: true,
          self: true,
          content: '巡检方式：GPS'
        },
        {
          free: true,
          stand: true,
          more: true,
          self: true,
          function: '任务',
          content: '普通巡检任务'
        },
        {
          free: false,
          stand: true,
          more: true,
          self: true,
          function: '任务',
          content: '周期性任务'
        },
        {
          free: true,
          stand: true,
          more: true,
          self: true,
          function: '异常工单',
          content: '创建、分发工单'
        },
        {
          free: '次数限制',
          stand: true,
          more: true,
          self: true,
          function: '数据统计',
          content: '日报'
        },
        {
          free: '次数限制',
          stand: true,
          more: true,
          self: true,
          function: '数据统计',
          content: '周报'
        },
        {
          free: false,
          stand: true,
          more: true,
          self: true,
          function: '数据统计',
          content: '月报'
        },
        {
          free: false,
          stand: true,
          more: true,
          self: true,
          function: '数据备份',
          content: '导出报表'
        },
        {
          free: '3个月',
          more: '1年',
          stand: '1年',
          self: '1年',
          function: '数据备份',
          content: '数据云端存储时长'
        },
        {
          free: false,
          stand: false,
          more: false,
          self: true,
          function: '定制化服务',
          content: '另行约定'
        },
        {
          free: '免费',
          stand: '5000元/年',
          more: '10000元/年',
          self: '按需报价',
          function: '价格',
          content: '软件服务费用'
        }
      ]
    };
  },
  components: {},
  computed: {
    user_info() {
      return this.$store.state.user_info;
    },
    service_level() {
      let arr = ['免费版本', '基础版本', '高级版本', '定制版本'];
      return arr[this.$store.state.current_project.organization.service_level];
    }
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0 || rowIndex === 15) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else if (rowIndex === 3) {
          return {
            rowspan: 4,
            colspan: 1
          };
        } else if (rowIndex === 7) {
          return {
            rowspan: 5,
            colspan: 1
          };
        } else if (rowIndex === 12 || rowIndex === 18) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else if (rowIndex === 14 || rowIndex === 21 || rowIndex === 20) {
          return {
            rowspan: 1,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 1) {
        return { display: 'none' };
      } else {
        if (columnIndex === 0) {
          if (rowIndex == 3) {
            return {
              rowspan: 3,
              colspan: 0
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
    },
    extension(url) {
      this.$router.push(url);
    }
  },
  created() {
    console.log('我是acd')

  },
  async mounted() {
    await this.$nextTick();
    let arrError = document.getElementsByClassName('close');
    arrError.forEach(element => {
      element.parentNode.parentNode.style.backgroundColor = '#ffc7ce';
    });
    let arrSuccess = document.getElementsByClassName('check');
    arrSuccess.forEach(element => {
      element.parentNode.parentNode.style.backgroundColor = '#c6efce';
    });
  },
  activated(){
    console.log('我是activated')
  },
  destory(){
    console.log('woshi destory')
  }
};
</script>
<style lang="scss" scoped>
.newProject {
  display: flex;
  // flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  position: relative;
  width: 1200px;
  height: 800px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  border: 1px solid #e6ecf5;
  margin: 20px auto;
  .left {
    flex: 0.8;
    padding-left: 30px;
    .title {
      font-size: $fontSizeMap-20;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .content {
    flex: 1.2;
    font-size: $fontSizeMap-20;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 28px;
    margin-top: 19px;
    margin-bottom: 50px;
    .title {
      font-size: $fontSizeMap-20;
      margin-top: 0;
      margin-bottom: 50px;
    }
  }
}
.nav {
  background-color: #fff;
  width: 1200px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  color: #666666;
  padding-left: 20px;
}
.el-table--mini /deep/ td,
.el-table--mini /deep/th {
  padding: 0;
}
.el-table /deep/.cell {
  // padding: 0;
  // height: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.el-table /deep/ td {
  width: 100px;
}
.nav_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 50px;
}
.nav_content {
  font-size: 16px;
  line-height: 50px;
}

.el-table__body {
  // 使表格兼容safari，不错位
  width: 100%;
  table-layout: fixed !important;
}
</style>
